import styled from 'styled-components';

import Row from '@Components/atoms/Row';
import { fadeInDown } from '@Components/helper/animation';

interface SelectionButtonProps {
  readonly active: boolean;
}

export const Root = styled(Row)`
  @media (min-width: ${(props) => props.theme.device.mobile}) {
    ${fadeInDown(0.5)}
  }
`;

export const VerticalDivider = styled.div`
  display: inline-block;
  align-self: stretch;
  width: 1.5px;
  background-color: ${(props) => props.theme.palette.text.tertiary};
  min-height: 1rem;
`;

export const SelectionButton = styled.div<SelectionButtonProps>`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.light.small.fontSize};
  font-weight: ${(props) => props.theme.font.text.light.small.fontWeight};
  letter-spacing: ${(props) => props.theme.font.text.light.small.letterSpacing};
  line-height: 20px; // todo: theme?

  top: -7px;
  left: -7px;
  padding: 5px 8px;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderRadius.button};

  background-color: ${(props) => (props.active ? props.theme.palette.input.background.active : 'none')};
  color: ${(props) => props.theme.palette.text.primary};
  transition:
    color ${(props) => props.theme.transition.theme},
    background-color ${(props) => props.theme.transition.theme};

  &:hover {
    background-color: ${(props) =>
      props.active ? props.theme.palette.input.background.active : props.theme.palette.input.background.hover};
  }
`;
