// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shuffleArray(a: any[]) {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export function roundToTwoDigits(value: number): number {
  return Number(value.toFixed(2));
}

export function roundTo2(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
