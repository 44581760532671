export function formatTime(duration: number): string {
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);
  let ret = '';
  ret += (hrs < 10 ? '0' : '') + hrs + ':' + (mins < 10 ? '0' : '');
  ret += mins + ':' + (secs < 10 ? '0' : '');
  ret += secs;
  return ret;
}

export const getTimeFromDateInSeconds = (date: Date | null) => {
  if (date === null) {
    return 0;
  }
  return Math.floor(new Date().getTime() - date.getTime()) / 1000;
};

export const getTimeFromDateInMilliseconds = (date: Date | null) => {
  if (date === null) {
    return 0;
  }
  return Math.floor(new Date().getTime() - date.getTime());
};

export const formatSeconds = (seconds: number) => {
  const roundedSeconds = Math.round(seconds);

  let min = '00';
  let sec = '00';

  if (roundedSeconds >= 60) {
    const tmp = Math.floor(roundedSeconds / 60);
    min = tmp > 9 ? tmp.toString() : '0' + tmp;
  }

  if (roundedSeconds % 60 > 0) {
    const tmp: number = roundedSeconds % 60;
    const tmpStr = Math.round(tmp).toString();
    sec = tmp > 9 ? tmpStr : '0' + tmpStr;
  }

  return min + ':' + sec;
};
