import {
  IconAlarm,
  IconChartLine,
  IconEye,
  IconSquareF1,
  IconSquareF2,
  IconSquareF3,
  IconSquareF4,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { TypingBoxSettingsModel } from '@Api/localStorage/TypingBoxSettingsModel';

import IconButton from '@Components/atoms/IconButton';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';
import { translateTypingTestFontSize, TypingTestFontSize } from '@Components/organisms/TypingBox/TypingBox';

import { iconStroke } from '@Assets/styles/theme/theme';

import { Root, SelectionButton, VerticalDivider } from './TypingBoxSettings.styles';

interface Props extends PropsWithClassName {
  settings: TypingBoxSettingsModel;
  updateSettings: (newSettings: TypingBoxSettingsModel) => void;
  handleFocus?: (isFocused: boolean) => void;
}

const TypingBoxSettings = (props: Props): React.ReactElement => {
  const { t } = useTranslation('typing-box');
  const theme = useTheme();

  const { settings, updateSettings, handleFocus } = props;

  const updateRows = (rows: number) => {
    settings.rows = rows;
    updateSettings(settings);
    typeof handleFocus === 'function' && handleFocus(true);
  };

  const updateUseTimer = (useTimer: boolean) => {
    settings.useTimer = useTimer;
    updateSettings(settings);
    typeof handleFocus === 'function' && handleFocus(true);
  };

  const updateShowInvisibleChars = (showInvisibleChars: boolean) => {
    settings.showInvisibleChars = showInvisibleChars;
    updateSettings(settings);
    typeof handleFocus === 'function' && handleFocus(true);
  };

  const updateLiveStats = (useLiveStats: boolean) => {
    settings.showStats = useLiveStats;
    updateSettings(settings);
    typeof handleFocus === 'function' && handleFocus(true);
  };

  const updateFontSize = (fontSize: TypingTestFontSize) => {
    settings.fontSize = fontSize;
    updateSettings(settings);
    typeof handleFocus === 'function' && handleFocus(true);
  };

  const updateFontFace = (fontFace: string) => {
    settings.fontFace = fontFace;
    updateSettings(settings);
    typeof handleFocus === 'function' && handleFocus(true);
  };

  const renderFontFaceOptions = () => {
    const fonts = [
      { icon: <IconSquareF1 strokeWidth={iconStroke} />, key: 'F1', font: theme.font.typingBoxWordsFont1 },
      { icon: <IconSquareF2 strokeWidth={iconStroke} />, key: 'F2', font: theme.font.typingBoxWordsFont2 },
      { icon: <IconSquareF3 strokeWidth={iconStroke} />, key: 'F3', font: theme.font.typingBoxWordsFont3 },
      { icon: <IconSquareF4 strokeWidth={iconStroke} />, key: 'F4', font: theme.font.typingBoxWordsFont4 },
    ];
    return (
      <Row noGutters rowGap={RowGap.Default} dataTestId={`TypingBoxSettings-fontFace`}>
        {fonts.map((font) => (
          <IconButton
            key={font.key}
            active={settings.fontFace === font.font}
            onClick={() => updateFontFace(font.font)}
            size="medium"
          >
            {font.icon}
          </IconButton>
        ))}
      </Row>
    );
  };

  const renderFontSizeOptions = () => {
    return (
      <Row noGutters rowGap={RowGap.Small}>
        {Object.values<TypingTestFontSize>(TypingTestFontSize).map((fontSize) => (
          <SelectionButton
            key={fontSize}
            active={settings.fontSize === fontSize}
            onClick={() => updateFontSize(fontSize)}
          >
            {translateTypingTestFontSize(fontSize, t)}
          </SelectionButton>
        ))}
      </Row>
    );
  };

  const renderRowsOptions = () => {
    const rowOptions = [1, 2, 3, 4, 5, 7, 10, 20, 30];

    return (
      <Row noGutters rowGap={RowGap.Small}>
        {rowOptions.map((rowOption) => (
          <SelectionButton key={rowOption} active={settings.rows === rowOption} onClick={() => updateRows(rowOption)}>
            {rowOption}
          </SelectionButton>
        ))}
      </Row>
    );
  };

  return (
    <Root
      noGutters
      rowGap={RowGap.Default}
      xsEnd
      xsMiddle
      className={props.className}
      dataTestId="TypingBoxSettings-root"
    >
      {renderFontFaceOptions()}
      <VerticalDivider />
      {renderFontSizeOptions()}
      <VerticalDivider />
      {renderRowsOptions()}
      <VerticalDivider />
      <Row noGutters rowGap={RowGap.Default}>
        <IconButton active={settings.showStats} onClick={() => updateLiveStats(!settings.showStats)} size="medium">
          <IconChartLine strokeWidth={iconStroke} />
        </IconButton>
        <IconButton active={settings.useTimer} onClick={() => updateUseTimer(!settings.useTimer)} size="medium">
          <IconAlarm strokeWidth={iconStroke} />
        </IconButton>
        <IconButton
          active={settings.showInvisibleChars}
          onClick={() => updateShowInvisibleChars(!settings.showInvisibleChars)}
          size="medium"
        >
          <IconEye strokeWidth={iconStroke} />
        </IconButton>
      </Row>
    </Root>
  );
};

export { TypingBoxSettings };
