import styled from 'styled-components';

import Row from '@Components/atoms/Row';
import Text from '@Components/atoms/Text';

import { UserSelectNone } from '@Assets/styles/theme/mixins';

interface TimeProps {
  readonly active: boolean;
}

export const Time = styled(Text)<TimeProps>`
  opacity: ${(props) => (props.active ? 1 : 0)};
  word-wrap: break-word;
  min-width: 2.5rem;
  ${UserSelectNone()};
`;

export const ControlsTop = styled(Row)`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;
