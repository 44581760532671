import { TypingMode } from '@Api/models/TypingTestResultModel';

export interface InputKey {
  value: string;
  usedTime: number;
  timestamp: number;
  code: string;
}

export enum TypeStatus {
  Untyped = 'untyped',
  Typed = 'typed',
  Active = 'active',
  Excluded = 'excluded',
  Error = 'error',
  Undefined = 'undefined',
  Extra = 'extra',
  Missing = 'missing',
}

export interface TypingCallbacks {
  onError?: (char: Char) => void;
  onWordFinish?: (word: Word) => void;
  onFinish?: (stats: TypingStatsState, words: Word[]) => void;
  onReset?: () => void;
}

export interface UserInputChar {
  value: string;
  usedTime: number;
  timestamp: number;
  status?: TypeStatus;
}

export enum UseTypingMode {
  TypingTest = 'typingTest',
  Competition = 'Competition',
  TextPractice = 'textPractice',
  Multiplayer = 'multiplayer',
}

export interface CharBlock {
  status: TypeStatus;
  wordStatus?: TypeStatus;
  chars: Char[];
  text: string;
}

export enum CharType {
  Char = 'char',
  Space = 'space',
  Linebreak = 'linebreak',
}

export interface Char {
  value: string;
  type: CharType;
  status: TypeStatus;
  startTime?: Date;
  endTime?: Date;
  // usedTime: number;
  wordStatus?: TypeStatus;
  userInput: UserInputChar[];
  keys?: InputKey[];
}

export type Word = {
  chars: Char[]; // expected chars, regardless of composed, upper- or lowercase
  status: TypeStatus;
  missedChars: number;
  extraKeys: number;
  modificationChars: number;
  modificationKeystrokes: number;
  keystrokes: number; // sum of expected keystrokes per char, calculated from keystrokesDictionary
  userInput: UserInputChar[]; // actual user input from input field, with upper-, lowercase and accents etc.
  keys: InputKey[]; // actual typed keys, incl. dead (non-visible) keys
  startTime?: Date;
  endTime?: Date;
  usedTime: number;
  wpmRaw: number;
  wpm: number;
  pace: number;
};

export interface TypingSettings {
  text: string;
  languageIso?: string;
  languageLabel?: string;
  typingMode: TypingMode;
  shuffle: boolean;
  durationInSeconds: number;
  skipWords: boolean; // if false words can't be switched until the word is typed correctly
  mode: UseTypingMode;
  charactersLength?: number;
  autoStart?: boolean;
}

export interface TypingSettingsState {
  settings: TypingSettings;
}

export interface TypingSettingsActions {
  setTypingSettings: (settings: TypingSettings, makeWords?: boolean) => void;
  setDurationInSeconds: (duration: number) => void;
  setText: (text: string) => void;
  setLanguage: (iso: string, label?: string) => void;
  setTypingMode: (mode: TypingMode) => void;
}

export interface TypingTimingState {
  startTime: Date | null;
  endTime: Date | null;
  isFinished: boolean;
  pastSeconds: number;
  lastStrokeTime: Date | null;
  lastStrokeTimeUserInput: Date | null;
  lastStrokeTimeKey: Date | null;
  wordTime: Date | null;
  isRunning: boolean;
}

export interface TypingTimingStateActions {
  start: () => void;
  finish: (callback?: (state: TypingStatsState, words: Word[]) => void) => void;
  updatePastSeconds: () => void;
  updateLastStrokeTime: () => void;
}

export interface TypingStatsState {
  // counter
  correctWords: number;
  correctChars: number; // sum of correct single chars
  correctWordChars: number; // sum of only correct chars of correct words
  correctKeystrokes: number; // sum of expected keystrokes of correct chars
  correctWordKeystrokes: number; // sum of only correct keystrokes of correct words
  wrongWords: number;
  wrongChars: number;
  wrongKeystrokes: number; // sum of expected keystrokes of wrong chars
  modificationChars: number; // sum of deleted chars
  modificationKeystrokes: number; // sum of deleted keystrokes
  spaces: number; // sum of typed spaces
  extraChars: number; // sum of extra chars
  extraKeys: number; // all unnecessary & extra keys
  extraKeystrokes: number; // sum of expected keystrokes of extra chars
  missedChars: number; // sum of missed chars
  missedKeystrokes: number; // sum of missed expected keystrokes
  totalKeys: number; // all typed keys incl. dead / not-visible keys
  totalCorrectKeys: number; // all keys of all correct chars
  duration: number;
  durationInSeconds: number; // convenience and backwards prop, same as duration
  // calculated
  accuracy: number; // Difference of correct chars and total chars as percentage.
  score: number; // Sum of correct chars and accuracy as a second measurement when same WPM
  wpm: number; // Sum of all chars and spaces  of all correct words calculated per minute divided by 5 (arbitrary word length).
  wpmRaw: number; // Sum of all chars and spaces of all words, regardless of correct or wrong, per minute divided by 5.
  pace: number; // Average time in milliseconds per char, regardless of correct or wrong.
  cpm: number; // Sum of all chars and spaces of all correct words calculated per minute.
  cpmRaw: number; //Sum of all chars and spaces of all words, regardless of correct or wrong, per minute.
  kpm: number; // Sum of all keys of all correct chars calculated per minute.
  kpmRaw: number; // Sum of all keys (correct, wrong, dead & extra) per minute.
  consistency: number; // Typing consistency over time resp. average deviation on keys amount per second.
}

export interface TypingStatsStateActions {}

export interface TypingState {
  timing: TypingTimingState;
  stats: TypingStatsState;
  words: Word[];
  composeValue: string | null;
  keysPerChar: number[];
  finishedWords: boolean;
  settings: TypingSettings;
}

export interface TypingGlobalActions {
  makeWords: () => void;
  handleDelete: (input: string, previousInputValue: string, keysPerChar: InputKey[]) => void;
  handleCharSwitch: (
    input: string,
    status: TypeStatus,
    keysPerChar: InputKey[],
    userInput: UserInputChar,
    typingCallbacks?: TypingCallbacks
  ) => void;
  handleWordSwitch: (
    keysPerChar: InputKey[],
    keysPerWord: InputKey[],
    userInput: UserInputChar,
    userInputPerWord: UserInputChar[],
    typingCallbacks?: TypingCallbacks
  ) => void;
  reset: () => void;
  resetStore: () => void;
}

export type TypingActions = TypingGlobalActions &
  TypingSettingsActions &
  TypingTimingStateActions &
  TypingStatsStateActions;
