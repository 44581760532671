import styled from 'styled-components';

export const IconBackground = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }

  &::before {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    padding: 10px;
    content: ' ';
    background: none;
    opacity: 0;
    transition:
      opacity 200ms ease-in-out,
      background-color ${(props) => props.theme.transition.theme};
  }

  &:hover::before,
  &:focus::before {
    background: ${(props) => props.theme.palette.input.background.hover};
    opacity: 1;
  }

  svg {
    position: relative;
    display: flex;
    color: ${(props) => props.theme.palette.text.primary};
    transition:
      fill ${(props) => props.theme.transition.theme},
      color ${(props) => props.theme.transition.theme};
    width: 1.25rem;
    height: 1.25rem;
  }
`;
