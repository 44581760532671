import { shuffleArray } from '@Root/src/helpers/utils';
import { Char, CharBlock, CharType, TypeStatus, Word } from '@Root/src/store/useTypingStore.types';

import { DEFAULT_LANGUAGE_ISO } from '@Components/helper/language';
import { ENTER_CHAR } from '@Components/molecules/WordBox/useTypingState';

import keystrokesDictionary from '@Assets/json/keystrokesDictionary.json';

export const calculateAccuracy = ({
  correctKeystrokes,
  wrongKeystrokes,
}: {
  correctKeystrokes: number;
  wrongKeystrokes: number;
}) => {
  const accuracy = Math.round((correctKeystrokes / (correctKeystrokes + wrongKeystrokes)) * 100);
  return isNaN(accuracy) ? 0 : accuracy;
};

export function calcConsistency(cov: number): number {
  return 100 * (1 - Math.tanh(cov + Math.pow(cov, 3) / 3 + Math.pow(cov, 5) / 5));
}

export const calculateKeystrokesForWord = (word: string, keystrokeLng: string): number => {
  let keystrokes = 0;

  for (let i = 0; i < word.length; i++) {
    keystrokes += calculateKeystrokesForChar(word[i], keystrokeLng);
  }

  return keystrokes;
};

export const getCorrectKeystrokesForWord = (word: Word, languageIso?: string): number => {
  return word.chars.reduce(
    (accumulator, char) =>
      char.status === TypeStatus.Typed
        ? accumulator + calculateKeystrokesForChar(char.value, languageIso)
        : accumulator,
    0
  );
};
export const getKeystrokesForWord = (word: Word, languageIso?: string): number => {
  return word.chars.reduce((accumulator, char) => accumulator + calculateKeystrokesForChar(char.value, languageIso), 0);
};

/**
 reduce active.words to sum of chars without Extra chars and calc keystrokes
 todo is with Space correct?
 */
export const getKeystrokesForWordWithoutExtra = (word: Word, languageIso?: string): number => {
  return word.chars.reduce(
    (accumulator, char) =>
      char.status !== TypeStatus.Extra
        ? accumulator + calculateKeystrokesForChar(char.value, languageIso)
        : accumulator,
    0
  );
};

export const calculateKeystrokesForChar = (char: string, keystrokeLng?: string): number => {
  let keystrokeKey = keystrokeLng;
  // language is not in keystrokes object
  if (!keystrokeLng || !(keystrokeLng in keystrokesDictionary)) {
    keystrokeKey = DEFAULT_LANGUAGE_ISO;
  }

  const lngKeystrokes = keystrokesDictionary[keystrokeKey as keyof typeof keystrokesDictionary];
  // todo can this be cached by  lang & char to improve performance ?
  const keystrokeKeys = Object.keys(lngKeystrokes).filter((key) => key.includes(char));

  if (keystrokeKeys.length > 1) {
    throw new Error(
      `multiple definitions of needed keystrokes found for character [${char}]: ${JSON.stringify(keystrokeKeys)}`
    );
  }

  const charKeystrokes = lngKeystrokes[keystrokeKeys[0] as keyof typeof lngKeystrokes];

  return charKeystrokes ?? 1; // if char was not found return 1 as default
};

export const calculateWordWpm = (wordLength: number, wordUsedTime: number) => {
  return Math.round((wordLength * (60 / (wordUsedTime / 1000))) / 5);
};

export function getCharsLengthWithoutExtra(word: Word): number {
  return word.chars.reduce((total, c) => (c.status !== TypeStatus.Extra ? total + 1 : total), 0);
}

const getValueByCharType = (char: Char, showInvisibleChars: boolean): string => {
  if (!showInvisibleChars) {
    return char.type === CharType.Linebreak ? ` ${char.value}` : char.value; // add whitespace before linebreak to have width for cursor
  }

  switch (char.type) {
    case CharType.Space:
      return '␣\xAD';
    case CharType.Linebreak:
      return `${ENTER_CHAR}\n\xAD`;
    default:
      return char.value;
  }
};

export const groupCharsNew = (words: Word[], showInvisibleChars: boolean) => {
  const charBlocks: CharBlock[] = [];
  words.forEach((word) => {
    word.chars.forEach((c) => {
      // todo test code
      const char = {
        ...c,
        value: getValueByCharType(c, showInvisibleChars),
        wordStatus: word.status,
      };
      if (
        charBlocks.length > 0 &&
        charBlocks[charBlocks.length - 1].status === c.status &&
        charBlocks[charBlocks.length - 1].wordStatus === word.status &&
        charBlocks[charBlocks.length - 1].status !== TypeStatus.Active
      ) {
        charBlocks[charBlocks.length - 1].chars.push(char);
      } else {
        charBlocks.push({
          status: c.status,
          wordStatus: word.status,
          chars: [char],
          text: '',
        });
      }
    });
  });

  return charBlocks.map((charBlock) => ({
    ...charBlock,
    text: charBlock.chars.map((c) => c.value).join(''),
  }));
};

/** this optimizes the array of words by grouping the chars of all words by their status.
 * This way we don't need to render one element for each character */
export const groupChars = (words: Word[], showInvisibleChars: boolean) => {
  // todo remove and use only groupCharsNew
  const chars: Char[] = [];

  words.forEach((word) => {
    chars.push(
      ...word.chars.map((c) => ({
        ...c,
        value: getValueByCharType(c, showInvisibleChars),
        wordStatus: word.status,
      }))
    );
  });

  const charBlocks = chars.reduce(
    (prev, next) => {
      const lastItem: Omit<CharBlock, 'text'> = prev[prev.length - 1];
      // todo If first word is only space then typing an error adds another space.
      //  This should not happen.
      if (
        (lastItem?.status === next.status && lastItem?.wordStatus === next?.wordStatus) ||
        (lastItem !== undefined &&
          lastItem?.status !== TypeStatus.Active &&
          lastItem?.status !== TypeStatus.Error &&
          next.type === CharType.Space && // todo check if word consists only of one space
          lastItem?.wordStatus !== TypeStatus.Active)
      ) {
        lastItem?.chars.push(next);
      } else {
        prev.push({
          status: next.status,
          wordStatus: next.wordStatus,
          chars: [next],
        });
      }

      return prev;
    },
    [] as Omit<CharBlock, 'text'>[]
  );

  return charBlocks.map((charBlock) => ({
    ...charBlock,
    text: charBlock.chars.map((c) => c.value).join(''),
  }));
};

export function isCorrect(word: Word): boolean {
  return word.chars.reduce((result, c) => result && c.status === TypeStatus.Typed, true);
}

export function isSpaceOrLinebreak(char: Char): boolean {
  return [CharType.Space, CharType.Linebreak].includes(char.type);
}

/**
 * Returns the average (mean) of an array of numbers as a single number.
 */
export function mean(array: number[]): number {
  try {
    return array.reduce((previous, current) => (current += previous)) / array.length;
  } catch (e) {
    return 0;
  }
}

/**
 * This code calculates the standard deviation of an array of numbers.
 * The first two lines store the length of the array (n) and the mean of the array in two variables.
 * The last part calculates the standard deviation by mapping each number in the array to the difference between it and the mean,
 * squaring the result, and then calculating the average of all the results. The final result is the standard deviation.
 */
export function stdDeviation(array: number[]): number {
  try {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
  } catch (e) {
    return 0;
  }
}

/** this goes through the string and splits it up into words and splits the words up into chars */
export const parseText = (text: string, keystrokeLng: string, shuffle?: boolean, charactersLength?: number) => {
  if (text === '') {
    return [];
  }

  // todo repeated text after splitting
  let repeatedText = text;
  if (charactersLength) {
    while (repeatedText.length < charactersLength) {
      repeatedText = repeatedText + '|' + text;
    }

    // To keep the performance while the user has the possibility to define a custom wordlist via the url, a maximum character limit can be specified.
    // This should always be high enough so that the user never reaches the end of the test during normal use.
    if (repeatedText.length > charactersLength) {
      repeatedText = repeatedText.substring(0, charactersLength);
    }
  }

  let splitText: string[] = [];
  // we split the text into words by space
  const splitTextArray: string[] = repeatedText.split('|');
  for (let i = 0; i < splitTextArray.length; i++) {
    // if there are linebreaks, they should separate words as well
    const newlineArray = splitTextArray[i].split('\n');
    if (newlineArray.length > 1) {
      // if linebreaks,
      // then we add the linebreak again except for the last element of the array which did not have a linebreak
      const newlineArrayWithNewline = newlineArray.map((value, index) =>
        index === newlineArray.length - 1 ? value : value + '\n'
      );
      splitText.push(...newlineArrayWithNewline);
    } else {
      splitText.push(...newlineArray);
    }
  }

  if (shuffle) {
    splitText = shuffleArray(splitText);
  }

  const words: Word[] = [];
  splitText.forEach((word, index) => {
    const isFirstWord = index === 0;
    const chars: Char[] = [];
    for (let i = 0; i < word.length; i++) {
      let type = CharType.Char;
      if (word[i] === '\n') {
        type = CharType.Linebreak;
      } else if (word[i] === ' ') {
        type = CharType.Space;
      }
      chars.push({
        value: word[i],
        type: type,
        status: isFirstWord && i === 0 ? TypeStatus.Active : TypeStatus.Untyped,
        userInput: [],
        keys: [],
      });
    }
    // add space as last char unless last char is \n
    if (chars.length === 0 || !chars[chars.length - 1].value.endsWith('\n')) {
      chars.push({
        value: ' ',
        type: CharType.Space,
        status: isFirstWord && chars.length === 0 ? TypeStatus.Active : TypeStatus.Untyped, // if first word is only space, it must be active
        userInput: [],
        keys: [],
      });
    }

    if (chars.length === 0) {
      words.push({
        chars,
        status: TypeStatus.Excluded,
        missedChars: 0,
        extraKeys: 0,
        modificationChars: 0,
        modificationKeystrokes: 0,
        keystrokes: 0,
        userInput: [],
        keys: [],
        startTime: undefined,
        endTime: undefined,
        usedTime: 0,
        wpmRaw: 0,
        wpm: 0,
        pace: 0,
      });
    } else {
      words.push({
        chars,
        status: isFirstWord ? TypeStatus.Active : TypeStatus.Untyped,
        missedChars: 0,
        extraKeys: 0,
        modificationChars: 0,
        modificationKeystrokes: 0,
        keystrokes: calculateKeystrokesForWord(word, keystrokeLng),
        userInput: [],
        keys: [],
        startTime: undefined,
        endTime: undefined,
        usedTime: 0,
        wpmRaw: 0,
        wpm: 0,
        pace: 0,
      });
    }
  });

  return words;
};
