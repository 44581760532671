import { TypingMode } from '@Api/models/TypingTestResultModel';

import { UseTypingMode } from '@Components/organisms/TypingBox/useTyping.types';

export const timingState = {
  startTime: null,
  endTime: null,
  isFinished: false,
  pastSeconds: 0,
  lastStrokeTime: null,
  lastStrokeTimeUserInput: null,
  lastStrokeTimeKey: null,
  wordTime: null,
  isRunning: false,
};

export const settings = {
  text: '',
  languageIso: '',
  languageLabel: '',
  typingMode: TypingMode.Normal,
  shuffle: true,
  durationInSeconds: 0,
  skipWords: true,
  charactersLength: 0,
  mode: UseTypingMode.TypingTest, // better naming: type
  autoStart: false,
};

export const stats = {
  correctWords: 0,
  correctWordChars: 0,
  correctWordKeystrokes: 0,
  correctChars: 0,
  correctKeystrokes: 0,
  wrongWords: 0,
  wrongChars: 0,
  wrongKeystrokes: 0,
  modificationChars: 0,
  modificationKeystrokes: 0,
  spaces: 0,
  extraChars: 0,
  extraKeys: 0,
  extraKeystrokes: 0,
  missedChars: 0,
  missedKeystrokes: 0,
  totalCorrectKeys: 0,
  totalKeys: 0,
  duration: 0,
  durationInSeconds: 0,
  // keystrokes: 0, // sum of expected keystrokes per char, calculated from keystrokesDictionary
  // userInput: [], // actual user input from input field, with upper-, lowercase and accents etc.
  // keys: [], // actual typed keys, incl. dead (non-visible) keys
  //
  accuracy: 0,
  score: 0,
  wpm: 0,
  wpmRaw: 0,
  pace: 0,
  cpm: 0,
  cpmRaw: 0,
  kpm: 0,
  kpmRaw: 0,
  consistency: 0,
};

export const state = {
  words: [],
  inputValue: '',
  composeValue: null,
  keysPerChar: [],
  finishedWords: false,
};
