import { IconSettings } from '@tabler/icons-react';
import { TFunction } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CompetitionStatus } from '@Api/models/CompetitionModel';
import { TypingMode } from '@Api/models/TypingTestResultModel';

import Col from '@Components/atoms/Col';
import Divider from '@Components/atoms/Divider';
import IconButton from '@Components/atoms/IconButton';
import ReloadButton from '@Components/atoms/ReloadButton/ReloadButton';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { formatSeconds } from '@Components/helper/time';
import TypingBoxSettings from '@Components/molecules/TypingBoxSettings';
import WordBox from '@Components/molecules/WordBox/WordBox';
import { TypingProps } from '@Components/organisms/TypingBox/useTyping.types';

import { TextSize } from '@Helpers/types/text';

import { iconStroke } from '@Assets/styles/theme/theme';

import { ControlsTop, Time } from './TypingBox.styles';

export enum TypingTestFontSize {
  VerySmall = 'verySmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  VeryLarge = 'veryLarge',
}

export const translateTypingTestFontSize = (fontSize: TypingTestFontSize, t: TFunction): string => {
  switch (fontSize) {
    case TypingTestFontSize.Small:
      return t('font_sizes.small', { ns: 'typing-box' });
    case TypingTestFontSize.Medium:
      return t('font_sizes.medium', { ns: 'typing-box' });
    case TypingTestFontSize.Large:
      return t('font_sizes.large', { ns: 'typing-box' });
    case TypingTestFontSize.VeryLarge:
      return t('font_sizes.veryLarge', { ns: 'typing-box' });
    default:
      return t('font_sizes.verySmall', { ns: 'typing-box' });
  }
};

interface Props extends PropsWithClassName {
  typingValues: TypingProps;
  competitionStatus?: CompetitionStatus;
  typingMode?: TypingMode;
  languageIso?: string;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  disableRefresh?: boolean;
  disableInput?: boolean;
}

/**
 * @deprecated TypingBoxState will replace this component
 */
const TypingBox = (props: Props): React.ReactElement => {
  const {
    typingValues,
    competitionStatus,
    typingMode,
    languageIso,
    headerLeft,
    headerRight,
    disableRefresh,
    disableInput,
  } = props;

  const { isFinished, hasLimitedTime, remainingSeconds, pastSeconds, resetTest } = typingValues;

  const { typingBoxSettings, updateTypingBoxSettings } = useGlobalContext();

  const inputRef = useRef<HTMLInputElement>(null);

  // view data only used in the typing box
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = (isFocused: boolean) => {
    if (isFocused) {
      inputRef.current?.focus();
    }
    setIsFocused(isFocused);
  };

  useEffect(() => {
    handleFocus(true);
  }, [languageIso, typingMode, disableInput]);

  const toggleSettings = (): void => {
    setShowSettings((prevShowSettings) => !prevShowSettings);
  };

  const wordBox = useMemo(
    () => (
      <WordBox
        isFocused={isFocused}
        handleFocus={handleFocus}
        inputRef={inputRef}
        typingValues={typingValues}
        settings={typingBoxSettings}
        competitionStatus={competitionStatus}
        languageIso={languageIso}
        disabled={isFinished || competitionStatus === CompetitionStatus.Completed || disableInput}
      />
    ),
    [isFocused, typingValues, typingBoxSettings, competitionStatus]
  );

  return (
    <div className={props.className} data-testid={'TypingBox-root'}>
      <ControlsTop xsBottom>
        <Col xs={6} sm={6} md={6} lg={6}>
          {headerLeft}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Row noGutters rowGap={RowGap.Large} columnGap={RowGap.Default} xsMiddle xsEnd>
            {headerRight}
            <Time size={TextSize.Small} active={typingBoxSettings.useTimer}>
              {formatSeconds(hasLimitedTime ? remainingSeconds ?? 0 : pastSeconds)}
            </Time>
            <IconButton active={showSettings} onClick={toggleSettings} dataTestId={`TypingBox-settings`}>
              <IconSettings strokeWidth={iconStroke} />
            </IconButton>
            {!disableRefresh && (
              <ReloadButton
                canFocusOnTab={isFocused}
                handleFocus={handleFocus}
                handleReload={() => {
                  resetTest();
                  handleFocus(true);
                }}
              />
            )}
          </Row>
        </Col>
      </ControlsTop>
      <Divider gradient />
      {showSettings && (
        <TypingBoxSettings
          settings={typingBoxSettings}
          updateSettings={updateTypingBoxSettings}
          handleFocus={handleFocus}
        />
      )}
      {!isFinished && wordBox}
    </div>
  );
};

export { TypingBox };
