import { InputHTMLAttributes } from 'react';

export enum TypeStatus {
  Untyped = 'untyped',
  Typed = 'typed',
  Active = 'active',
  Excluded = 'excluded',
  Error = 'error',
  Undefined = 'undefined',
  Extra = 'extra',
  Missing = 'missing',
}

export enum CharType {
  Char = 'char',
  Space = 'space',
  Linebreak = 'linebreak',
}

export enum UseTypingMode {
  TypingTest = 'typingTest',
  Competition = 'Competition',
  TextPractice = 'textPractice',
  Multiplayer = 'multiplayer',
}

export interface TypingSettings {
  shuffle: boolean;
  durationInSeconds: number;
  skipWords: boolean; // if false words can't be switched until the word is typed correctly
  mode: UseTypingMode;
  charactersLength?: number;
  autoStart?: boolean;
}

export interface TypingCallbacks {
  onError?: (char: Char) => void;
  onWordFinish?: (word: Word) => void;
  onFinish?: (stats: TypingStats, words: Word[]) => void;
  onReset?: () => void;
}

export interface Char {
  value: string;
  type: CharType;
  status: TypeStatus;
  usedTime: number;
  wordStatus?: TypeStatus;
  userInput: UserInputChar[];
  keys?: InputKey[];
}

export interface UserInputChar {
  value: string;
  usedTime: number;
  timestamp: number;
  status?: TypeStatus;
}

export interface InputKey {
  value: string;
  usedTime: number;
  timestamp: number;
  code: string;
}

export type Word = {
  chars: Char[]; // expected chars, regardless of composed, upper- or lowercase
  status: TypeStatus;
  missedChars: number;
  extraKeys: number;
  modificationChars: number;
  modificationKeystrokes: number;
  keystrokes: number; // sum of expected keystrokes per char, calculated from keystrokesDictionary
  userInput: UserInputChar[]; // actual user input from input field, with upper-, lowercase and accents etc.
  keys: InputKey[]; // actual typed keys, incl. dead (non-visible) keys
  usedTime: number;
  wpmRaw: number;
  wpm: number;
  pace: number;
};

export interface TypingStats {
  /*
  glossary:
  chars: expected chars, regardless of composed, upper- or lowercase
  keystrokes: sum of expected keystrokes per char (calculated based on keystrokesDictionary)
  userInput: actual user input from input field, with upper-, lowercase and accents etc.
  keys: actual typed keys, with all possibly typed extra dead (non-visible) keys
   */
  // counter
  correctWords: number;
  correctChars: number; // sum of correct single chars
  correctKeystrokes: number; // sum of expected keystrokes of correct chars
  wrongWords: number;
  wrongChars: number;
  wrongKeystrokes: number; // sum of expected keystrokes of wrong chars
  modificationChars: number; // sum of deleted chars
  modificationKeystrokes: number; // sum of deleted keystrokes
  spaces: number; // sum of typed spaces
  extraChars: number; // sum of extra chars
  extraKeystrokes: number; // sum of expected keystrokes of extra chars
  missedChars: number; // sum of missed chars
  missedKeystrokes: number; // sum of missed expected keystrokes
  totalKeys: number; // all typed keys incl. dead / not-visible keys
  extraKeys: number; // all unnecessary & extra keys
  durationInSeconds: number;
  // calculated
  accuracy: number; // Difference of correct chars and total chars as percentage.
  score: number; // Sum of correct chars and accuracy as a second measurement when same WPM
  wpm: number; // Sum of all chars and spaces  of all correct words calculated per minute divided by 5 (arbitrary word length).
  wpmRaw: number; // Sum of all chars and spaces of all words, regardless of correct or wrong, per minute divided by 5.
  pace: number; // Average time in miliseconds per char, regardless of correct or wrong.
  cpm: number; // Sum of all chars and spaces of all correct words calculated per minute.
  cpmRaw: number; //Sum of all chars and spaces of all words, regardless of correct or wrong, per minute.
  kpm: number; // Sum of all keys of all correct words calculated per minute.
  kpmRaw: number; // Sum of all keys (correct, wrong, dead & extra) per minute.
  consistency: number; // Typing consistency over time resp. average deviation on keys amount per second.
}

export interface TypingProps {
  words: Word[];
  charBlocks: CharBlock[];
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  resetTest: () => void;
  isFinished: boolean;
  isRunning: boolean;
  stats: TypingStats;
  hasLimitedTime: boolean;
  remainingSeconds?: number;
  pastSeconds: number;
}

export interface CharBlock {
  status: TypeStatus;
  wordStatus?: TypeStatus;
  chars: Char[];
}
